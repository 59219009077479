<template>
  <div class="py-3 min-w-0 w-full">
    <div class="display-1 d-flex">
      <span>Muokkaa vakuuslaskua</span>
      <v-spacer></v-spacer>
      <v-btn color="info" @click="goBack()">Takaisin</v-btn>
    </div>

    <deposit-invoice-form
      :contr="contract"
      save-btn-text="Tallenna luonnos"
      @sendinvoice="submit($event)"
    ></deposit-invoice-form>
  </div>
</template>

<script>
import DepositInvoiceForm from "../../components/Invoice/DepositInvoiceForm";
import mixins from "../../mixins/mixins";
import axiosMethods from "../../mixins/axios";
import { mapMutations } from "vuex";

export default {
  title: "Muokkaa vakuuslaskua",

  mixins: [mixins],

  components: {
    DepositInvoiceForm,
  },

  data() {
    return {
      invoice: {
        otherDueDates: {},
        products: [],
        sendType: "post",
        freeText:
          "Vuokranmaksupäivä on joka kuukauden viides päivä. Maksa vuokra aina samalla viitteellä.",
      },
      contract: {},
    };
  },

  async created() {
    try {
      this.setLoading(true);

      let res = await axiosMethods.get(`/api/v1/invoices/${this.$route.params.id}`);
      let invoice = res.data.invoice;

      this.contract = invoice.contract;

      let inv = {
        billDate: invoice.billDate
          ? new Date(invoice.billDate).toISOString().substring(0, 10)
          : new Date(new Date()).toISOString().substring(0, 10),
        accountDate: new Date(invoice.accountDate).toISOString().substring(0, 10),
        dueDate: new Date(invoice.dueDate).toISOString().substring(0, 10),
        billDateAsDueDate: invoice.billDateAsDueDate,
        sendStatus: invoice.sendStatus,
        otherDueDates: invoice.otherDueDates,
        paymentTerm: invoice.paymentTerm,
        firstPaymentTerm: invoice.firstPaymentTerm,
        tenant: invoice.tenant,
        isRecurrent: invoice.isRecurrent,
        refundInvoiceIds: invoice.refundInvoiceIds,
        shareAmountPerDays: invoice.shareAmountPerDays,
        autoReminder: invoice.autoReminder,
        nextDueDate: invoice.nextDueDate,
        invoiceInterval: invoice.invoiceInterval,
        addAttachment: invoice.addAttachment,
        userCreatedAttachment: invoice.userCreatedAttachment,
        base64Pdf: {
          fileName: invoice.base64Pdf?.fileName || "",
          base64FileString: invoice.base64Pdf?.base64FileString || "",
        },
        pdfEditorContent: invoice.pdfEditorContent,
        freeText: invoice.freeText,
        vatFreeText: invoice.vatFreeText,
        sendType: invoice.sendType,
        autoSendType: invoice.autoSendType,
        products: invoice.products,
        deposit: invoice.deposit,
        sendToApartmentAddress: invoice.sendToApartmentAddress,
        sendingAddress: invoice.sendingAddress
          ? invoice.sendingAddress
          : { address: null, zipCode: null, city: null },
        evoice: invoice.evoice,
        contract: invoice.contract,
        outgoingPayments: invoice.outgoingPayments,
        costCentre: invoice.costCentre,
        yourCode: invoice.yourCode,
        language: invoice.language ? invoice.language : "fin",
      };

      this.invoice = inv;
    } catch (err) {
      this.showPopup(err, "error");
    }
  },

  methods: {
    ...mapMutations("invoice", ["setLoading", "setSaving"]),

    async submit(data) {
      try {
        await axiosMethods.update(`/api/v1/invoices/edit/${this.$route.params.id}`, data);
        this.setSaving(false);

        this.$router.push("/invoices?tab=1");
        this.showPopup("Laskua muokattu", "success");
      } catch (err) {
        this.setSaving(false);
        this.showPopup(err, "error");
      }
    },
  },
};
</script>

<style scoped></style>
